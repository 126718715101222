import axios from "axios";
import { notify } from "../components/Notify";
import Config from "../Config";

export async function UserLogin(email, password, remember, callback) {
  axios({
    method: "POST",
    url: Config.server + "admin/login",
    withCredentials: true,
    data: {
      email: email,
      password: password,
      remember: remember,
    },
  })
    .then((res) => {
      if (res.data.login) {
        callback("success");

        let d = new Date();
        let expire = d.getTime() + parseInt(3600000);

        window.localStorage.setItem(
          "industrial-dashboard-admin-panel-login",
          res.data.login
        );
        window.localStorage.setItem(
          "industrial-dashboard-admin-panel-user",
          res.data.data.user
        );
        window.localStorage.setItem(
          "industrial-dashboard-admin-panel-id",
          res.data.data.id
        );
        window.localStorage.setItem(
          "industrial-dashboard-admin-panel-role",
          res.data.data.role
        );
        window.localStorage.setItem(
          "industrial-dashboard-admin-panel-state",
          res.data.data.state
        );
        window.localStorage.setItem(
          "industrial-dashboard-admin-panel-access",
          res.data.data.access
        );
        window.localStorage.setItem(
          "industrial-dashboard-admin-panel-expires",
          expire
        );

        window.location.assign("/");
      } else {
        callback("failed");

        notify("info", "unautharized access");
      }
    })
    .catch((err) => {
      callback("failed");

      if (err.response) {
        if (err.response.status === 401) {
          notify("info", "unautharized access");
        } else if (err.response.data.message) {
          notify("error", err.response.data.message);
        }
      } else {
        notify("error", "Error");
      }
    });
}

export async function checkLogin(callback) {
  axios({
    method: "GET",
    url: Config.server + "admin/checkLogin",
    withCredentials: true,
  })
    .then((res) => {
      callback(res.data.status);
    })
    .catch((err) => {
      callback("failed");
    });
}
