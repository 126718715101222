import axios from 'axios';
import { notify } from '../components/Notify';
import Config from '../Config';
import { UserLogout } from './logout';

export async function getContacts(callback) {
    axios({
        method: 'GET',
        url: Config.server + 'api/getContacts',
        withCredentials: true
    })
    .then(res => {
        callback(res.data.data)
    })
    .catch(err => {
        callback(null)

        if(err.response){
            if(err.response.status === 401) {
                UserLogout()
            }

            if(err.response.data.message){
                notify('error', err.response.data.message)
            }
        }else{
            notify('error', 'Error')
        }
    })
}

export async function findContacts(string, callback) {
    axios({
        method: 'GET',
        url: Config.server + 'api/findContacts/' + string,
        withCredentials: true
    })
    .then(res => {
        callback(res.data.data)
    })
    .catch(err => {
        callback(null)

        if(err.response){
            if(err.response.status === 401) {
                UserLogout()
            }

            if(err.response.data.message){
                notify('error', err.response.data.message)
            }
        }else{
            notify('error', 'Error')
        }
    })
}