import { lazy, Suspense, useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";
import PreLoadingScreen from "./components/PreLoadingScreen";
import "./styles/App.scss";

import Navbar from "./components/Navbar/Navbar";
import NotificationContainer from "./components/Notify";
import { checkLogin } from "./api/login";
import DemoRequests from "./pages/DemoRequests";
import generateLicense from "./pages/generateLicense";

const Home = lazy(() => import("./pages/Home"));
const Login = lazy(() => import("./pages/Login"));
const Devices = lazy(() => import("./pages/Devices"));
const Profile = lazy(() => import("./pages/Profile"));
const Users = lazy(() => import("./pages/Users"));
const Leads = lazy(() => import("./pages/Leads"));
const Members = lazy(() => import("./pages/Members"));
const Shop = lazy(() => import("./pages/Shop"));
const Blues = lazy(() => import("./pages/Blues"))
const Item = lazy(() => import("./pages/Item"));
const CellularOrders = lazy(() => import("./pages/CellularOrders"));
const Cellulars = lazy(() => import("./pages/Cellulars"));
const Cellular = lazy(() => import("./pages/Cellular"));
const Coupons = lazy(() => import("./pages/Coupons"));
const License = lazy(() => import("./pages/showLicense"))
const Reminders = lazy(() => import("./pages/Reminders"))
const GenerateLicense = lazy(() => import("./pages/generateLicense"))


function App() {
  const [LoggedIn, setLoggedIn] = useState(false);

  let loginState = window.localStorage.getItem(
    "industrial-dashboard-admin-panel-login"
  );

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      checkLogin((state) => {
        if (state === "success" && loginState === "true") {
          setLoggedIn(true);
        } else {
          setLoggedIn(false);
        }
      });
    }

    return () => {
      isMounted = false;
    };
  }, [loginState]);

  return (
    <div className="App">
      <Switch>
        {LoggedIn ? (
          <>
            <Navbar>
              <Suspense fallback={<PreLoadingScreen LoggedIn={true} />}>
                <Route exact path="/" component={Home} />
                <Route exact path="/devices" component={Devices} />
                <Route exact path="/profile" component={Profile} />
                <Route exact path="/users" component={Users} />
                <Route exact path="/members" component={Members} />
                <Route exact path="/shop" component={Shop} />
                <Route exact path="/item" component={Item} />
                <Route exact path="/leads" component={Leads} />
                <Route exact path="/demorequests" component={DemoRequests} />
                <Route exact path="/blues" component={Blues} />
                <Route exact path="/license" component={License} />
                <Route exact path="/paymentNotifications" component={Reminders} />
                <Route exact path="/generate-license" component={generateLicense} />
                <Route
                  exact
                  path="/cellular-orders"
                  component={CellularOrders}
                />
                <Route exact path="/cellulars" component={Cellulars} />
                <Route exact path="/cellular" component={Cellular} />
                <Route exact path="/coupons" component={Coupons} />
              </Suspense>
            </Navbar>
          </>
        ) : (
          <Suspense fallback={<PreLoadingScreen />}>
            <Route exact component={Login} />
          </Suspense>
        )}
      </Switch>
      <NotificationContainer />
    </div>
  );
}

export default App;
