import React, { useCallback, useEffect, useState } from "react";
import { Button, DialogActions, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { createLicense } from '../api/generateLicense';


function GenerateLicense() {

    const [ValidateTime, setValidateTime] = useState("");

    function GenerateLicenseDetails() {
        const username = document.getElementById("userName");


        createLicense({
            username: username.value,
            expirationDays: ValidateTime
        }, (status) => {
            if (status) {
                // console.log("555555555555555555555555555555555555555555555555555555")
                // console.log(res.data)
            }
        })

    }

    function handleOnValidateTime(event) {
        setValidateTime(event.target.value)
    }



    return (

        <div>
            <div className="grid-item">
                <div className="card">

                    <div className="section">
                        <div className="top">
                            <p className="title">Generate License</p>
                        </div>


                        <TextField id="userName" className="material-input" size="small" label="Username" variant="outlined" style={{ marginBottom: '20px' }} required />

                        <FormControl size="small" fullWidth>
                            <InputLabel id="demo-select-small-label">Validity Period</InputLabel>
                            <Select
                                size="small"
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                value={ValidateTime}
                                label="Validity Period"
                                required
                                onChange={handleOnValidateTime}
                            >
                                <MenuItem value={'365'}>1 Year</MenuItem>
                                <MenuItem value={'1095'}>3 Years</MenuItem>
                                <MenuItem value={'3650'}>10 Years</MenuItem>
                            </Select>
                        </FormControl>

                        <div className="button_wrap">
                            <Button size="small" type="submit" variant="contained" className="material-button" onClick={() => GenerateLicenseDetails()}>Save</Button>
                        </div>

                    </div>

                </div>
            </div>
        </div>

    )

}

export default GenerateLicense;