import React from "react";
import Avatar from "@mui/material/Avatar";
import { IconButton } from "@mui/material";
import { HiMenu } from "react-icons/hi";
import { useHistory } from "react-router";
import Config from "../../Config";

export default function TopBar({ User, MenuState, onMenuState }) {
  const histroy = useHistory();

  function handleOnMenuClick() {
    onMenuState(true);
  }

  function handleOnProfileClick() {
    histroy.push("/profile");
  }

  return (
    <div id="topbar">
      <IconButton
        color="default"
        aria-label="Menu Bar"
        onClick={() => handleOnMenuClick()}
      >
        <HiMenu id="menu_icon" />
      </IconButton>

      <div className="avatar" onClick={handleOnProfileClick}>
        <Avatar
          src={User.image ? Config.server + User.image : "/broken-image.jpg"}
        />

        <div className="text_wrap">
          <p className="name">
            {User.fname} {User.lname}
          </p>
          <p className="tag">{User.role}</p>
        </div>
      </div>
    </div>
  );
}
