import axios from "axios";
import { notify } from "../components/Notify";
import Config from "../Config";
import { UserLogout } from "./logout";

export async function getUser(callback) {
  let user = window.localStorage.getItem(
    "industrial-dashboard-admin-panel-user"
  );

  if (!user) {
    return;
  }

  axios({
    method: "GET",
    url: Config.server + "admin/getUser/" + user,
    withCredentials: true,
  })
    .then((res) => {
      if (!res.data.data) {
        callback(null);
        notify("info", "No records");
        return;
      }

      callback(res.data.data);
    })
    .catch((err) => {
      callback(null);
      console.log(err.response);
      if (err.response) {
        if (err.response.status === 401) {
          UserLogout();
        }

        if (err.response.data.message) {
          notify("error", err.response.data.message);
        }
      } else {
        notify("error", "Error");
      }
    });
}

export async function getUsers(callback) {
  axios({
    method: "GET",
    url: Config.server + "admin/getUsers",
    withCredentials: true,
  })
    .then((res) => {
      callback(res.data.data);
    })
    .catch((err) => {
      callback(null);
      console.log(err.response);
      if (err.response) {
        if (err.response.status === 401) {
          UserLogout();
        }

        if (err.response.data.message) {
          notify("error", err.response.data.message);
        }
      } else {
        console.log("err", err);
        notify("error", "Error");
      }
    });
}

export async function findUser(string, callback) {
  axios({
    method: "GET",
    url: Config.server + "admin/findUser/" + string,
    withCredentials: true,
  })
    .then((res) => {
      callback(res.data.data);
    })
    .catch((err) => {
      callback(null);
      console.log(err.response);
      if (err.response) {
        if (err.response.status === 401) {
          UserLogout();
        }

        if (err.response.data.message) {
          notify("error", err.response.data.message);
        }
      } else {
        notify("error", "Error");
      }
    });
}

export async function updateUserImage(image, callback) {
  let user = window.localStorage.getItem(
    "industrial-dashboard-admin-panel-user"
  );

  let data = new FormData();

  data.append("email", user);
  data.append("image", image.files[0]);

  axios({
    method: "PATCH",
    url: Config.server + "admin/updateUserImage",
    data: data,
    withCredentials: true,
  })
    .then((res) => {
      notify(res.data.status, res.data.message);
      callback(res.data.status);
    })
    .catch((err) => {
      callback("failed");
      if (err.response) {
        if (err.response.status === 401) {
          UserLogout();
        }

        if (err.response.data.message) {
          notify("error", err.response.data.message);
        }
      } else {
        notify("error", "Error");
      }
    });
}

export async function updateUser(data, callback) {
  let user = window.localStorage.getItem(
    "industrial-dashboard-admin-panel-user"
  );

  data["email"] = user;

  axios({
    method: "PATCH",
    url: Config.server + "admin/updateUser",
    data: data,
    withCredentials: true,
  })
    .then((res) => {
      notify(res.data.status, res.data.message);
      callback(res.data.status);
    })
    .catch((err) => {
      callback("failed");
      console.log(err);
      if (err.response) {
        if (err.response.status === 401) {
          UserLogout();
        }

        if (err.response.data.message) {
          notify("error", err.response.data.message);
        }
      } else {
        notify("error", "Error");
      }
    });
}

export async function addUser(data, callback) {
  axios({
    method: "POST",
    url: Config.server + "admin/addUser",
    data: data,
    withCredentials: true,
  })
    .then((res) => {
      notify(res.data.status, res.data.message);
      callback(res.data.status);
    })
    .catch((err) => {
      callback("failed");
      console.log(err);
      if (err.response) {
        if (err.response.status === 401) {
          UserLogout();
        }

        if (err.response.data.message) {
          notify("error", err.response.data.message);
        }
      } else {
        notify("error", "Error");
      }
    });
}

export async function deleteMember(data, callback) {
  axios({
    method: "DELETE",
    url: Config.server + "admin/deleteUser",
    data: data,
    withCredentials: true,
  })
    .then((res) => {
      notify(res.data.status, res.data.message);
      callback(res.data.status);
    })
    .catch((err) => {
      callback("failed");
      console.log(err);
      if (err.response) {
        if (err.response.status === 401) {
          UserLogout();
        }

        if (err.response.data.message) {
          notify("error", err.response.data.message);
        }
      } else {
        notify("error", "Error");
      }
    });
}
