import { notify } from "../components/Notify";
import axios from "axios";
import Config from "../Config";

export async function UserLogout() {
  let state = window.localStorage.getItem(
    "industrial-dashboard-admin-panel-state"
  );
  let token = window.localStorage.getItem(
    "industrial-dashboard-admin-panel-access"
  );

  if (state === "true" || state === true) {
    axios({
      method: "POST",
      url: Config.server + "admin/logout",
      withCredentials: true,
      data: {
        token: token,
      },
    })
      .then((res) => {
        window.localStorage.removeItem(
          "industrial-dashboard-admin-panel-login"
        );
        window.localStorage.removeItem("industrial-dashboard-admin-panel-user");
        window.localStorage.removeItem("industrial-dashboard-admin-panel-id");
        window.localStorage.removeItem("industrial-dashboard-admin-panel-role");
        window.localStorage.removeItem(
          "industrial-dashboard-admin-panel-state"
        );
        window.localStorage.removeItem(
          "industrial-dashboard-admin-panel-access"
        );
        window.localStorage.removeItem(
          "industrial-dashboard-admin-panel-expires"
        );

        setTimeout(() => {
          window.location.assign("/");
        }, 500);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.data.message) {
            notify("error", err.response.data.message);
          }
        } else {
          notify("error", "Error");
        }
      });
  } else if (state !== "true" || state !== true) {
    axios({
      method: "POST",
      url: Config.server + "admin/logout",
      withCredentials: true,
      data: {
        token: token,
      },
    })
      .then((res) => {
        window.localStorage.removeItem(
          "industrial-dashboard-admin-panel-login"
        );
        window.localStorage.removeItem("industrial-dashboard-admin-panel-user");
        window.localStorage.removeItem("industrial-dashboard-admin-panel-id");
        window.localStorage.removeItem("industrial-dashboard-admin-panel-role");
        window.localStorage.removeItem(
          "industrial-dashboard-admin-panel-state"
        );
        window.localStorage.removeItem(
          "industrial-dashboard-admin-panel-access"
        );
        window.localStorage.removeItem(
          "industrial-dashboard-admin-panel-expires"
        );

        setTimeout(() => {
          window.location.assign("/");
        }, 500);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.data.message) {
            notify("error", err.response.data.message);
          }
        } else {
          notify("error", "Error");
        }
      });
  }
  {
    window.localStorage.removeItem("industrial-dashboard-admin-panel-login");
    window.localStorage.removeItem("industrial-dashboard-admin-panel-user");
    window.localStorage.removeItem("industrial-dashboard-admin-panel-id");
    window.localStorage.removeItem("industrial-dashboard-admin-panel-role");
    window.localStorage.removeItem("industrial-dashboard-admin-panel-state");
    window.localStorage.removeItem("industrial-dashboard-admin-panel-access");
    window.localStorage.removeItem("industrial-dashboard-admin-panel-expires");

    setTimeout(() => {
      window.location.assign("/");
    }, 1500);
  }
}
