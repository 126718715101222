import React from 'react'

export default function PreLoadingScreen({LoggedIn}) {
    return (
        <div id="preLoadingScreen" data-logged={LoggedIn}>
            <div className='container'>
                <div className='loader'>
                    <div className='loader--dot'></div>
                    <div className='loader--dot'></div>
                    <div className='loader--dot'></div>
                    <div className='loader--dot'></div>
                    <div className='loader--dot'></div>
                    <div className='loader--dot'></div>
                </div>
            </div>
        </div>
    )
}
