import React, { useEffect, useState } from 'react'
import { getUser } from '../../api/user'
import SideBar from './SideBar'
import TopBar from './TopBar'

export var updateNavbarUser;

export default function Navbar({children}) {
    const [MenuState, setMenuState] = useState(false)
    const [User, setUser] = useState([])

    function handleOnMenuStateChange(state) {
        setMenuState(state)
    }

    useEffect(() => {
        let isMounted = true;

        if(isMounted) {
            fetchUser()
        }

        return () => {isMounted = false}
    }, [])

    updateNavbarUser = () => {
        fetchUser()
    }

    function fetchUser() {
        getUser((data) => {
            if(data) setUser(data)
        })
    }

    return (
        <div id="navbar">
            <SideBar MenuState={MenuState} onMenuState={handleOnMenuStateChange} />

            <div id="content_section">
                <div id="nav_top">
                    <TopBar User={User} MenuState={MenuState} onMenuState={handleOnMenuStateChange} />
                </div>

                <div id="nav_body">
                    {children}
                </div>
            </div>
        </div>
    )
}
