import axios from 'axios';
import { notify } from '../components/Notify';
import Config from '../Config';


export async function createLicense(body, callback) {

    console.log(body)

    axios({
        method: 'POST',
        url: Config.server + 'admin/generateLicense',
        data: body,
        withCredentials: true
    })
    .then(res => {
        console.log("*******************************************")
        console.log(res.data)
        notify('success', "License generated successfully" )
        callback(res.data)
    })
    .catch(err => {
        callback(null)

        if(err.response){
            if(err.response.status === 401) {
                
            }

            if(err.response.data.message){
                notify('error', err.response.data.message)
            }
        }else{
            notify('error', 'Error')
        }
    })
}