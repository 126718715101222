import React, { useEffect, useState } from 'react';
import { TableRow, TableCell, TextField, } from '@mui/material';
import { findContacts, getContacts } from '../api/contacts';
import PageTable from '../components/PageTable';
import moment from 'moment';

export default function DemoRequests() {
    const [ContactsData, setContactsData] = useState([]);

    useEffect(() => {
        let isMounted = true;

        if(isMounted) {
            fetchContacts()
        }

        return () => {isMounted = false}
    }, [])

    function fetchContacts() {
        getContacts(data => {
            if(data) setContactsData(data)
        })
    }

    function handleOnSearchTxtChange(event) {
        let value = event.target.value

        if(value) {
            findContacts(value, (data) => {
                if(data) setContactsData(data)
            })    
        }else{
            fetchContacts()
        }
    }

    const TableHead = () => {
        return (
            <TableRow>
                <TableCell align="center" >NAME</TableCell>
                <TableCell align="center" >EMAIL</TableCell>
                <TableCell align="center" >COMPANY</TableCell>
                <TableCell align="center" >PHONE</TableCell>
                <TableCell align="center" >SUBMISSION</TableCell>
            </TableRow>
        )
    }

    const TableDataCell = ({data}) => {
        return (
            <TableRow>
                <TableCell align="center" >{data.name|| '-'}</TableCell>
                <TableCell align="center" >{data.email || '-'}</TableCell>
                <TableCell align="center" >{data.company || '-'}</TableCell>
                <TableCell align="center" >{data.phone || '-'}</TableCell>
                <TableCell align="center" >{moment(data.createdAt).format('YYYY-MM-DD hh:mm:ss')}</TableCell>
            </TableRow>
        )
    }

    return (
        <div id="demorequests" className="container">
             <div className="container-top">
                <h1 className="title">Demo Requests</h1>
            </div>

            <div className="body">

                <div className="top">
                    <div className="search-bar">
                        <TextField
                            id="demo-request-find-txt"
                            placeholder="Search"
                            size="small"
                            fullWidth
                            onChange={handleOnSearchTxtChange}
                        />
                    </div>
                </div>
                
                <PageTable Rows={ContactsData} Head={TableHead} DataCell={TableDataCell} />
            </div>
        </div>
    )
}
