require('dotenv').config()

const {
    REACT_APP_DEVELOPMENT,
    REACT_APP_SERVER,
    REACT_APP_MAP_KEY,
} = process.env

module.exports = {
    development: REACT_APP_DEVELOPMENT ? REACT_APP_DEVELOPMENT === 'true' ? true : false : false ,
    server: REACT_APP_SERVER,
    map_key: REACT_APP_MAP_KEY,
}